			 $('.slider-for').slick({
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  arrows: false,
			  fade: true,
			  centerMode: true,
			  asNavFor: '.slider-nav'
			});
			$('.slider-nav').slick({
			  slidesToShow: 4,
			  slidesToScroll: 1,
			  asNavFor: '.slider-for',
			  dots: false,
			  centerMode: true,
			  focusOnSelect: true,
			  centerPadding: 0,
			  responsive: [
			    {
			      breakpoint: 600,
			      settings: {
			        slidesToShow: 3,
			      }
			    }

			  ]
			});